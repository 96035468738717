const basicRoutes = [
	{
		path: "/",
		name: "",
		title: "可视化_new",
		redirect: "/showView",
	},
	{
		path: "/showView",
		name: "showView",
		title: "可视化",
		redirect: "",
		component: () => import("../views/adminManage/secendChart/secendChart.vue"),
	},
];

export default basicRoutes;
